import { navigate } from 'gatsby';
import { TextArea, TextInput } from 'grommet';
import { Button } from 'nzk-react-components';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import useData from '../data/AssignmentsLineup/useData';
import { colors } from '../../../../theme'

const Wrapper = styled.div`
  padding-bottom: 90px;
  h1 {
    text-align: center;
    color: ${colors.indigo};
  }
`

const Form = styled.div`
  max-width: 450px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0,0,0,0.4);
  > * { margin-bottom: 10px; }
  > :last-child { margin-bottom: 0; }
`

const Field = styled.div`

`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  > * { margin-right: 10px; }
  > :last-child { margin-right: 0; }
`


interface IFormData {
  title: string
  description: string
}

const EducationCategoryCreatePage = (props: { categoryId?: string }) => {
  const { fetchEducationCategory, updateEducationCategory, createEducationCategory } = useData()
  const [formData, setFormData] = useState<IFormData>({
    title: '',
    description: '',
  })

  useEffect(() => {
    if (props.categoryId) {
      const fetch = async () => {
        const data: any = await fetchEducationCategory(props.categoryId!)
        setFormData({
          title: data.title,
          description: data.description,
        })
      }
      fetch()
    }
  }, [])

  const onSubmit = async () => {
    if (props.categoryId) {
      const category = await updateEducationCategory(props.categoryId, formData)
      if (category._id) {
        navigate(`/star-tutoring/education-categories`)
      }
    } else {
      const category = await createEducationCategory(formData)
      if (category._id) {
        navigate(`/star-tutoring/education-categories`)
      }
    }
  }

  const onCancel = () => {
    if (props.categoryId) {
      navigate(`/star-tutoring/education-categories`)
    } else {
      navigate(`/star-tutoring/education-categories`)
    }
  }

  return <Wrapper>
    <h1>{props.categoryId ? 'Edit' : 'Create'} education category</h1>
    <Form>
      <Field>
        <div>Title</div>
        <TextInput
          value={formData.title}
          type='text'
          onChange={e => {
            const { value } = e.target
            setFormData(d => ({ ...d, title: value }))
          }}
          placeholder='Title'
        />
      </Field>
      <Field>
        <div>Description</div>
        <TextArea
          value={formData.description}
          onChange={e => {
            const { value } = e.target
            setFormData(d => ({ ...d, description: value }))
          }}
          placeholder='Description'
        />
      </Field>
      <Actions>
        <Button size='small' theme='confirm' disabled onClick={onCancel}>Cancel</Button>
        <Button size='small' theme='confirm' onClick={onSubmit}>{props.categoryId ? 'Update' : 'Create'}</Button>
      </Actions>
    </Form>
  </Wrapper>
}

EducationCategoryCreatePage.defaultProps = {
  categoryId: undefined
}

export default EducationCategoryCreatePage
